import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { BusinessParamsService } from 'src/app/business-params.service';
import { BikeRequestBusinessParamsEnum } from './../../../shared/BusinessParams/BikeRequestBusinessParams';
import { MobilityRequestType } from 'src/core/enum/mobility-request-type.enum';
import { BikeRequestDto } from 'src/core/models/BikeRequestDto';
import { BusinessParam } from 'src/core/models/business-param';
import { RequestBike } from 'src/core/models/request-bike';
import User from 'src/core/models/user';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { DateService } from 'src/core/services/date.service';
import { HttpClientService } from 'src/core/services/http-client.service';
import { MoneyService } from 'src/core/services/money.service';
import { PdfService } from 'src/core/services/pdf.service';
import { FileType } from 'src/core/enum/FileTypeEnum';
import { MobilityRequestStatus } from 'src/core/enum/mobility-request-status.enum';
import { environment } from 'src/environments/environment';
import { FileService } from 'src/app/shared/file.service';
import { Canstants } from 'src/core/canstants/canstants';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from 'src/app/template/ErrorDialog/ErrorDialog/ErrorDialog.component';
moment.locale('fr');
moment.tz.setDefault('Europe/Paris');
@Component({
  selector: 'app-update-bike',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css'],
})
export class UpdateBikeComponent implements OnInit {
  private attachmentToBlob!: Blob;
  @ViewChild('openModalNegativeNumber')
  openModalNegativeNumber!: ElementRef;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dateService: DateService,
    private spinner: NgxSpinnerService,
    private pdfService: PdfService,
    private moneyService: MoneyService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private businessParamService: BusinessParamsService,
    private httpService: HttpClientService<any>,
    private fileService: FileService,
    private dialog: MatDialog
  ) {
    this.initializeForm();
  }

  submitted = false;
  minDate!: Date;
  maxDate!: Date;
  reCalculateEvent = true;
  requestBike: RequestBike = new RequestBike();
  requestBikeDto: BikeRequestDto = new BikeRequestDto();
  connectedUser: User | undefined;
  peoplePlan: any;
  condition1: boolean = false;
  businessParam: any;
  listStartMonth: Array<any> = [];
  // @ts-ignore
  bikeRequestForm: FormGroup = null;
  applicableMonthlyPayment!: number;
  maxRefundAmount!: number;
  doIn = '';
  dateNow = new Date();
  demeurantIn = '';
  attachmentFile: any = {};
  attestationFile: any = {};
  isValid = false;
  changeAttestation = false;
  changeAttachment = false;
  attachmentIdToDelete!: number;
  attestationIdToDelete!: number;
  fileExists: Boolean = true;
  fileGreater: Boolean = false;
  @ViewChild('openModalStartDate')
  openModalStartDate!: ElementRef;

  get form(): any {
    return this.bikeRequestForm.controls;
  }

  async ngOnInit(): Promise<void> {
    this.calculatePurchaseDate();
    this.connectedUser = await this.authService.getConnectedUser();
    await this.httpService.findAll('BusinessParams').subscribe((bp) => {
      let formattedMonth = moment().format('MM');
      let title =
        Canstants.limitValidVeloM + moment().format('yyyy') + formattedMonth;
      this.businessParam = bp.filter(
        (param: any) => param.title == title
      )[0].descriptionBP;
      this.getBikeRequest();
    });
    let monthlyPay = BikeRequestBusinessParamsEnum.monthlyPayment;
    let maxRefundableAmount = BikeRequestBusinessParamsEnum.maxAmount;
    this.businessParamService.getBusinessParams().subscribe({
      next: (data: BusinessParam[]) => {
        data.forEach((item) => {
          if (item.title == monthlyPay && item.descriptionBP)
            this.applicableMonthlyPayment = Number.parseFloat(
              item.descriptionBP
            );
          if (item.title == maxRefundableAmount && item.descriptionBP)
            this.maxRefundAmount = Number.parseFloat(item.descriptionBP);
        });
      },
      error(err) {},
    });
  }
  get environement(): typeof environment {
    return environment;
  }
  get mobilityRequestStatus(): typeof MobilityRequestStatus {
    return MobilityRequestStatus;
  }
  async getBikeRequest(): Promise<void> {
    let today = new Date();
    let currentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    this.httpService
      .findOneById('BikeRequests', this.activatedRoute.snapshot.params['id'])
      .subscribe((result: any) => {
        this.requestBike = result;
        this.bikeRequestForm.controls['controleComment'].setValue(
          this.requestBike.controlerComment
        );
        let startDate = new Date(
          new Date(this.requestBike.startDate).getFullYear(),
          new Date(this.requestBike.startDate).getMonth(),
          1
        );
        if (
          startDate < currentMonth ||
          (startDate.toISOString() == currentMonth.toISOString() &&
            today.getDate() > Number(this.businessParam))
        ) {
          this.openModalStartDate.nativeElement.click();
        } else {
          this.initializeRequest();
        }
      });
  }
  async changeStartDate(): Promise<void> {
    const dateNow = new Date();
    const nextMonth = moment().add(1, 'month');
    let numberFullMonths = this.requestBike.numberFullMonths;
    let startDate;
    if (dateNow.getDate() > Number(this.businessParam)) {
      this.bikeRequestForm.controls.startDate.setValue(
        nextMonth.format('MMMM YYYY')
      );
      this.bikeRequestForm.controls.startMonth.setValue(
        nextMonth.format('MMMM YYYY')
      );
      startDate = nextMonth;
    } else {
      this.bikeRequestForm.controls.startDate.setValue(
        moment().format('MMMM YYYY')
      );
      this.bikeRequestForm.controls.startMonth.setValue(
        moment().format('MMMM YYYY')
      );
      startDate = moment().startOf('month');
    }
    startDate.date(1);
    this.requestBike.startDate = startDate.toDate();
    if (this.bikeRequestForm && this.bikeRequestForm.controls.endDate) {
      const formattedEndDate = startDate.add(numberFullMonths, 'months');
      this.bikeRequestForm.patchValue({
        endDate: formattedEndDate.format('MMMM YYYY'),
      });
      formattedEndDate.date(1);
      this.requestBike.endDate = formattedEndDate.toDate();
    }

    this.bikeRequestForm.controls.requesterComment.setValue(
      this.requestBike.requesterComment ? this.requestBike.requesterComment : ''
    );
    this.bikeRequestForm.controls.purchaseAmount.setValue(
      this.moneyService.format(
        (this.requestBike?.purchaseAmount || '').toString(),
        '.'
      )
    );

    this.bikeRequestForm.controls.purchaseDate.setValue(
      moment(this.requestBike.purchaseDate)
    );
    this.bikeRequestForm.controls.subsidizedAmount.setValue(
      this.moneyService.format(
        (this.requestBike.subsidizedAmount || '').toString(),
        '.'
      )
    );
    this.bikeRequestForm.controls.numberFullMonths.setValue(
      this.requestBike.numberFullMonths
    );
    this.bikeRequestForm.controls.vae.setValue(this.requestBike.vae);
    this.bikeRequestForm.controls.applicableMonthlyPayment.setValue(
      this.moneyService.format(
        (this.requestBike.applicableMonthlyPayment || '').toString(),
        '.'
      )
    );
    this.bikeRequestForm.controls.lastMonthAmount.setValue(
      this.moneyService.format(
        this.requestBike?.lastMonthAmount?.toString(),
        '.'
      )
    );
    this.bikeRequestForm.controls.attachmentFile.setValue(
      this.requestBike.proofOfPurchase
    );
    this.bikeRequestForm.controls.attestationFile.setValue(
      this.requestBike.certificateFMD
    );
  }
  async initializeRequest(): Promise<void> {
    this.bikeRequestForm.controls.startMonth.setValue(
      moment(this.requestBike.startDate).format('YYYY-MM')
    ); // EXP: 2021-07
    this.bikeRequestForm.controls.requesterComment.setValue(
      this.requestBike.requesterComment
    );
    this.bikeRequestForm.controls.purchaseAmount.setValue(
      this.moneyService.format(
        (this.requestBike?.purchaseAmount || '').toString(),
        '.'
      )
    );
    this.bikeRequestForm.controls.purchaseDate.setValue(
      this.requestBike.purchaseDate
    );
    this.bikeRequestForm.controls.subsidizedAmount.setValue(
      this.moneyService.format(
        (this.requestBike.subsidizedAmount || '').toString(),
        '.'
      )
    );
    this.bikeRequestForm.controls.numberFullMonths.setValue(
      this.requestBike.numberFullMonths
    );
    this.bikeRequestForm.controls.vae.setValue(this.requestBike.vae);
    this.bikeRequestForm.controls.applicableMonthlyPayment.setValue(
      this.moneyService.format(
        (this.requestBike.applicableMonthlyPayment || '').toString(),
        '.'
      )
    );
    this.bikeRequestForm.controls.lastMonthAmount.setValue(
      this.moneyService.format(
        this.requestBike?.lastMonthAmount?.toString(),
        '.'
      )
    );
    if (this.requestBike.vae == null) {
      this.bikeRequestForm.controls.vae.setValue(false);
    } else {
      this.bikeRequestForm.controls.vae.setValue(this.requestBike.vae);
    }
    this.bikeRequestForm.controls.attachmentFile.setValue(
      this.requestBike.proofOfPurchase
    );
    this.bikeRequestForm.controls.attestationFile.setValue(
      this.requestBike.certificateFMD
    );
    this.bikeRequestForm.controls.startDate.setValue(
      moment(this.requestBike.startDate).format('MMMM YYYY')
    );
    this.bikeRequestForm.controls.endDate.setValue(
      moment(this.requestBike.endDate).format('MMMM YYYY')
    );
  }

  private initializeForm(): void {
    this.bikeRequestForm = this.formBuilder.group({
      startMonth: [''],
      requesterComment: [''],
      purchaseAmount: [''],
      purchaseDate: [''],
      subsidizedAmount: [''],
      numberFullMonths: [''],
      attestationFile: ['', Validators.required],
      attachmentFile: ['', Validators.required],
      applicableMonthlyPayment: [''],
      lastMonthAmount: [''],
      startDate: [''],
      endDate: [''],
      controleComment: [''],
      vae: [''],
    });
  }

  calculatePurchaseDate(): any {
    const currentDate = moment();
    this.minDate = moment('2021-01-01').toDate();
    this.maxDate = currentDate.toDate();
  }

  changePurchaseDate(event: any): any {
    this.bikeRequestForm.controls.purchaseDate.setValue(
      moment(event.value).toDate()
    );
    this.reCalculateEvent = true;
    this.isValid = true;
  }

  changeStartMonth(): void {
    this.reCalculateEvent = true;
    this.isValid = true;
  }

  changePurchaseAmount(value: any): void {
    let isnum = /^(\d{1,3}( \d{3})*)(,\d+)?$/.test(value.target.value);
    if (!isnum) {
      this.openModalNegativeNumber.nativeElement.click();
      this.bikeRequestForm.controls['purchaseAmount'].setValue('');
    } else {
      this.reCalculateEvent = true;
      this.isValid = true;
    }
   
  }

  calculatePayment(): void {
    this.reCalculateEvent = false;
    this.isValid = false;
    if (this.bikeRequestForm.controls.purchaseAmount) {
      let subsidizedAmount = 0;
      let applicableMonthlyPayment = 0;
      let numberFullMonths = 0;
      let lastMonthAmount = 0;
      const startDate = moment(this.bikeRequestForm.controls.startMonth.value);
      const purchaseAmount = Number(
        this.bikeRequestForm.controls.purchaseAmount.value
          .replace(',', '.')
          .replace(' ', '')
      );

      if (purchaseAmount > 800) {
        subsidizedAmount = 400;
      } else {
        subsidizedAmount = Number((purchaseAmount / 2).toFixed(2));
      }
      applicableMonthlyPayment = this.applicableMonthlyPayment;
      if (subsidizedAmount === 400) {
        numberFullMonths = 12;
      } else {
        numberFullMonths = Math.floor(
          subsidizedAmount / applicableMonthlyPayment
        );
        lastMonthAmount = Number(
          (
            subsidizedAmount -
            applicableMonthlyPayment * numberFullMonths
          ).toFixed(2)
        );
      }
      this.bikeRequestForm.controls.subsidizedAmount.setValue(
        this.moneyService.format(subsidizedAmount.toString(), '.')
      );
      this.bikeRequestForm.controls.applicableMonthlyPayment.setValue(
        this.moneyService.format(applicableMonthlyPayment.toString(), '.')
      );
      this.bikeRequestForm.controls.numberFullMonths.setValue(numberFullMonths);

      this.bikeRequestForm.controls.lastMonthAmount.setValue(
        this.moneyService.format(lastMonthAmount.toString(), '.')
      );
      // this.bikeRequestForm.controls.purchaseAmount.setValue(
      //   this.moneyService.format(purchaseAmount.toString(), '.')
      // );
      // this.bikeRequestForm.controls.startDate.setValue(
      //   startDate.format('MMMM YYYY')
      // );
      this.bikeRequestForm.controls.startDate.setValue(
        moment(this.requestBike.startDate).format('MMMM YYYY')
      );
      let endDate;
      if (lastMonthAmount === 0) {
        endDate = moment(this.requestBike.startDate).add(
          numberFullMonths - 1,
          'months'
        );
      } else {
        endDate = moment(this.requestBike.startDate).add(
          numberFullMonths,
          'months'
        );
      }
      this.bikeRequestForm.controls.endDate.setValue(
        endDate.format('MMMM YYYY')
      );
    }
  }

  async generatePDF(): Promise<void> {
    await this.pdfService.generatePDF(
      0,
      this.doIn,
      this.demeurantIn,
      MobilityRequestType.cycling
    );
    let fileBlob = this.pdfService.pdf.output('blob') as File;
    this.bikeRequestForm.controls.attestationFile.setValue(fileBlob);
    this.requestBike.certificateFMD = fileBlob;
    this.requestBikeDto.certificateFMD = fileBlob;
    this.requestBikeDto.requesterAddress = this.demeurantIn;
  }

  async openPdf(): Promise<void> {
    // await this.pdfService.generatePDF(0, this.doIn, this.demeurantIn, MobilityRequestType.cycling);
    // this.pdfService.openPdf();
    this.pdfService.openPdf();
  }
  openAttachment(): void {
    window.open(URL.createObjectURL(this.attachmentToBlob));
  }
  async updateBikeRequest(cancelRequest?: boolean): Promise<void> {
    if (this.requestBike.id) {
      if (cancelRequest) {
        this.httpService
          .patchFormData('BikeRequests', this.requestBike.id, {
            id: this.requestBike.id,
            cancelRequest,
          })
          .subscribe((data) => {
            this.router.navigate(['/mobility-request/list']);
          });
        return;
      }

      this.submitted = true;
      if (!this.bikeRequestForm.valid) {
        return;
      }
      const startDate = new Date(this.requestBike.startDate);
      startDate.setHours(12, 0, 0, 0);
      this.requestBikeDto.startDate = startDate.toISOString();

      // this.requestBikeDto.startDate = new Date(
      //   this.requestBike.startDate
      // ).toISOString();

      this.requestBikeDto.purchaseAmount = Number(
        this.bikeRequestForm.value.purchaseAmount
          .replace(/\s/g, '')
          .replace(',', '.')
      );
      this.requestBikeDto.purchaseDate = new Date(
        this.bikeRequestForm.value.purchaseDate
      ).toISOString();
      this.requestBikeDto.status = this.mobilityRequestStatus.Submitted;
      this.requestBikeDto.requesterComment = this.bikeRequestForm.value
        .requesterComment
        ? this.bikeRequestForm.value.requesterComment
        : '';
      this.requestBikeDto.vae = this.bikeRequestForm.value.vae;
      this.requestBikeDto.requesterAddress =
        this.demeurantIn || this.requestBike.requesterAddress;
      this.httpService
        .patchFormData('BikeRequests', this.requestBike.id, {
          id: this.requestBike.id,
          ...this.requestBikeDto,
        })
        .subscribe(
          (data) => {
            this.router.navigate(['/mobility-request/list']);
          },
          (error) => {
            if (error.status === 500) {
              this.dialog.open(ErrorDialogComponent, {
                data: { message: 'Oups, problème système' },
              });
            }
          }
        );
    }
  }

  async uploadAttachment(event: any): Promise<void> {
    this.bikeRequestForm.controls.attachmentFile.setValue(
      event.target.files[0]
    );
    this.requestBike.proofOfPurchase = event.target.files[0];

    this.requestBikeDto.proofOfPurchase = event.target.files[0];
    this.requestBikeDto.ProofOfPurchaseFileType =
      FileType[event.target.files[0].type as keyof typeof FileType];
    const fileSize = event.target.files[0].size;
    const isFileSizeGreaterThan1MB = fileSize > 1024 * 1024;
    this.fileExists = true;
    this.fileGreater = false;
    if (isFileSizeGreaterThan1MB) {
      this.fileGreater = true;
    }
  }

  removeAttachment(): void {
    this.requestBike.proofOfPurchase = undefined;
    this.attachmentFile = null;
    this.bikeRequestForm.controls.attachmentFile.setValue(null);
    this.requestBike.proofOfPurchase = undefined;
    this.requestBikeDto.ProofOfPurchaseFileType = undefined;
    this.fileExists = false;
    this.fileGreater = false;
  }

  removeAttestation(): void {
    this.attestationFile = null;
    this.demeurantIn = '';
    this.doIn = '';
    this.condition1 = false;
    this.pdfService.resetPDF();
    this.bikeRequestForm.controls.attestationFile.setValue(null);
    this.requestBike.certificateFMD = undefined;
    this.requestBikeDto.requesterAddress = undefined;
  }

  toNumber(value: string): number {
    return Number(value.replace(',', '.').replace(' ', ''));
  }
  openFile(bikeFile?: any): void {
    if (bikeFile?.fileBytes) this.fileService.openFile(bikeFile.fileBytes);
  }
}
