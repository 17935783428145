import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateBikeComponent } from './update/update.component';
import { ViewBikeComponent } from './view/view.component';
import { ListComponent } from './list/list.component';
import { AngularMaterialModule } from 'src/core/angular-material/angular-material.module';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import routes from '../mobility-request-admin.routing';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IConfig, NgxMaskModule } from 'ngx-mask';
const maskConfig: Partial<IConfig> = {
  validation: false,
  thousandSeparator: ' ',
  decimalMarker: ',',
};
@NgModule({
  declarations: [UpdateBikeComponent, ViewBikeComponent, ListComponent],
  exports: [UpdateBikeComponent, ViewBikeComponent, ListComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule.forRoot(routes),
    AngularMaterialModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(maskConfig)
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BikeModule {}
