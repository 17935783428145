import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { HttpClientService } from 'src/core/services/http-client.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gestion-remboursements',
  templateUrl: './gestion-remboursements.component.html',
  styleUrls: ['./gestion-remboursements.component.css']
})
export class GestionRemboursementsComponent implements OnInit {
  pageSize = 10;
  currentPage = 1;
  totalRows = 0;
  displayedColumns: string[] = [];
  SubscriptionTypeRefundsList: any[] = [];
  SubscriptionTypeRefundId: any = 0;
  SubscriptionTypeFormulaType: any = 0;
  refundForm: any = null;
  updateRefundForm: any = null;
  refundToUpdate: any = null;
  previousValues: { [key: string]: any } = {};
  titleSubscription!:string;


  @ViewChild('openAddRefund')
  openAddRefund!: ElementRef;
  @ViewChild('closeAddButton')
  closeAddButton!: ElementRef;

  @ViewChild('openUpdateRefund')
  openUpdateRefund!: ElementRef;
  @ViewChild('closeUpdate')
  closeUpdate!: ElementRef;
  @ViewChild('openModalNegativeNumber')
  openModalNegativeNumber!: ElementRef;


  constructor(
    private httpService: HttpClientService<any>,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }
  get environement(): typeof environment {
    return environment;
  }

  async ngOnInit(): Promise<void> {
    this.SubscriptionTypeRefundId = this.activatedRoute.snapshot.params['id'];
    this.SubscriptionTypeFormulaType = this.activatedRoute.snapshot.params['type'];
    this.initializeHeader();
    this.initializeForm();
    this.initializeUpdateForm();
    this.getSubscriptionTypeById(this.SubscriptionTypeRefundId);
    await this.loadSubsriptionTypeRefunds()
  }
  getSubscriptionTypeById(id:any){
    this.httpService.findAll('SubscriptionTypes/id', {
      Id:id
    })
    .subscribe((result: any) => {
      this.titleSubscription = result.title

    });
  }
  openAddModal() {
    this.openAddRefund.nativeElement.click();
  }
  openUpdateModal(id: any) {
    this.initializeUpdateForm();
    this.openUpdateRefund.nativeElement.click();
    this.getformulaById(id);
  }
  getformulaById(id: any) {
    this.httpService.findAll('SubscriptionTypeRefunds/id', {
      Id: id
    })
      .subscribe((result: any) => {
        this.refundToUpdate = result;
        this.populateForm(this.refundToUpdate);
      });
  }

  private populateForm(formula: any): void {
    this.updateRefundForm.controls['valueDate'].setValue(
      formula.valueDate
    );
    this.updateRefundForm.controls['percent'].setValue(
      formula.percent ? 1 : 0
    );
    this.updateRefundForm.controls['monthlyRefundRate'].setValue(
      formula.monthlyRefundRate
    );
    this.updateRefundForm.controls['unitRefundRate'].setValue(
      formula.unitRefundRate
    );
    this.updateRefundForm.controls['annualFees'].setValue(
      formula.annualFees
    );
    this.updateRefundForm.controls['monthlyFees'].setValue(
      formula.monthlyFees
    );
    this.updateRefundForm.controls['otherFees'].setValue(
      formula.otherFees
    );
    this.previousValues['monthlyRefundRate']=formula.monthlyRefundRate;
    this.previousValues['unitRefundRate']=formula.unitRefundRate;
    this.previousValues['annualFees']=formula.annualFees;
    this.previousValues['monthlyFees']=formula.monthlyFees;
    this.previousValues['otherFees']=formula.otherFees;
  }
  private initializeUpdateForm(): void {
    this.updateRefundForm = new FormGroup({
      valueDate: new FormControl(''),
      percent: new FormControl('0'),
      monthlyRefundRate: new FormControl(''),
      unitRefundRate: new FormControl(''),
      annualFees: new FormControl(''),
      monthlyFees: new FormControl(''),
      otherFees: new FormControl(''),
    });
  }

  private initializeForm(): void {
    this.refundForm = new FormGroup({
      valueDate: new FormControl(''),
      percent: new FormControl('0'),
      monthlyRefundRate: new FormControl(''),
      unitRefundRate: new FormControl(''),
      annualFees: new FormControl(''),
      monthlyFees: new FormControl(''),
      otherFees: new FormControl(''),
    });
  }

  UpdateRefund() {
    this.httpService
      .patchFormData('SubscriptionTypeRefunds', this.refundToUpdate.id, {
        Id: this.refundToUpdate.id,
        SubscriptionTypeId: this.refundToUpdate.subscriptionTypeId,
        ValueDate: this.refundToUpdate.valueDate,
        MonthlyRefundRate: this.updateRefundForm?.value?.monthlyRefundRate ?
        this.updateRefundForm?.value?.monthlyRefundRate.toString().includes('.') ?
          this.updateRefundForm?.value?.monthlyRefundRate.toString().replace('.', ',')
          : this.updateRefundForm?.value?.monthlyRefundRate.toString()
        : null,
        UnitRefundRate: this.updateRefundForm?.value?.unitRefundRate ?
          this.updateRefundForm?.value?.unitRefundRate.toString().includes('.') ?
            this.updateRefundForm?.value?.unitRefundRate.toString().replace('.', ',')
            : this.updateRefundForm?.value?.unitRefundRate.toString()
          : null,
        AnnualFees: this.updateRefundForm?.value?.annualFees ?
          this.updateRefundForm?.value?.annualFees.toString().includes('.') ?
            this.updateRefundForm?.value?.annualFees.toString().replace('.', ',')
            : this.updateRefundForm?.value?.annualFees.toString()
          : null,

        MonthlyFees: this.updateRefundForm?.value?.monthlyFees ?
          this.updateRefundForm?.value?.monthlyFees.toString().includes('.') ?
            this.updateRefundForm?.value?.monthlyFees.toString().replace('.', ',')
            : this.updateRefundForm?.value?.monthlyFees.toString()
          : null,

        OtherFees: this.updateRefundForm?.value?.otherFees ?
          this.updateRefundForm?.value?.otherFees.toString().includes('.') ?
            this.updateRefundForm?.value?.otherFees.toString().replace('.', ',')
            : this.updateRefundForm?.value?.otherFees.toString()
          : null,

        Percent: this.refundToUpdate.percent?true:false,
      })
      .subscribe((data) => {
        this.closeUpdate.nativeElement.click();
        this.loadSubsriptionTypeRefunds();
      });


  }

  async loadSubsriptionTypeRefunds(fromFilter?: boolean): Promise<void> {
    this.httpService
      .findAll('SubscriptionTypeRefunds', {
        pageSize: this.pageSize,
        pageNumber: this.currentPage,
        SubscriptionTypeId: this.SubscriptionTypeRefundId,
      })
      .subscribe((st) => {
        this.SubscriptionTypeRefundsList = st.items;
        this.totalRows = st.totalCount;
      });
    this.displayedColumns = [
      'valueDate',
      'percent',
      'monthlyRefundRate',
      'unitRefundRate',
      'annualFees',
      'monthlyFees',
      'otherFees',
      'Id'
    ];
  }
  replaceCommaWithDot(value:number){
    return value.toString().replace(/\./g, ',');
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.loadSubsriptionTypeRefunds();
  }

  initializeHeader(): void {
    this.displayedColumns = [
      'valueDate',
      'percent',
      'monthlyRefundRate',
      'unitRefundRate',
      'annualFees',
      'monthlyFees',
      'otherFees',
      'Id'

    ];
  }

  changePurchaseDate(event: any): any {
    let date = moment(event.value);
    date.date(1);
    this.refundForm.controls.valueDate.setValue(date.toISOString());
    this.updateRefundForm.controls.valueDate.setValue(date.toISOString());
  }

  addRefund() {
    let dateValue = new Date(this.refundForm.value.valueDate);
    dateValue.setUTCHours(0, 0, 0, 0);
    dateValue.setUTCDate(dateValue.getUTCDate() + 1);
    var newDateValue = dateValue.toISOString();
    if (this.refundForm.valid) {
      let payload: any = {
        SubscriptionTypeId: this.SubscriptionTypeRefundId,
        ValueDate: newDateValue,
        MonthlyRefundRate: this.refundForm?.value.monthlyRefundRate,
        UnitRefundRate: this.refundForm?.value.unitRefundRate,
        AnnualFees: this.refundForm?.value.annualFees,
        MonthlyFees: this.refundForm?.value.monthlyFees,
        OtherFees: this.refundForm?.value.otherFees,
        Percent: this.refundForm?.value.percent == "1" ? true : false,
      };

      this.httpService.postFormData('SubscriptionTypeRefunds', payload).subscribe({
        next: (data) => {
          this.closeAddButton.nativeElement.click();
          this.initializeForm()
          this.loadSubsriptionTypeRefunds();
        },
        error(err) { },
      });
    }

  }
  cancelAddFormula() {
    this.initializeForm()
  }
  testFormat(value: any, controlName: string) {
    let isnum = /^(\d+,)*(\d+)$/.test(value.target.value);
    let b = parseFloat('0,00');
    let a = parseFloat(value.target.value);
    if (!isnum || a == b) {
      this.refundForm.controls[controlName].setValue('');
    }
  }

  testFormatUpdate(value: any, controlName: string) {
    let isnum = /^(\d+,)*(\d+)$/.test(value.target.value);
    let b = parseFloat('0,00');
    let a = parseFloat(value.target.value);
    if (!isnum || a == b) {
      this.updateRefundForm.controls[controlName].setValue(this.previousValues[controlName]);
    }
  }



}
