import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { FormuleTypeEnum } from 'src/core/enum/formule-type-enum';
import { HttpClientService } from 'src/core/services/http-client.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gestion-formules',
  templateUrl: './gestion-formules.component.html',
  styleUrls: ['./gestion-formules.component.css']
})
export class GestionFormulesComponent implements OnInit {
  pageSize = 10;
  currentPage = 1;
  totalRows = 0;
  SubscriptionTypesList: any[] = [];
  SubscriptionTypesListAll: any[] = [];
  formuleTypes: any[] = [];
  displayedColumns: string[] = [];
  SubscriptionFilter: any = null;
  listSites: any;
  formulaToUpdate :any=null;
  formulaForm = new FormGroup({
    title: new FormControl(''),
    formulaType: new FormControl(''),
    formulaCode: new FormControl(''),
    order: new FormControl(''),
    siteId: new FormControl(''),
  });
  updateFormulaForm = new FormGroup({
    title: new FormControl(''),
    formulaType: new FormControl(''),
    formulaCode: new FormControl(''),
    order: new FormControl(''),
    siteId: new FormControl(''),
  });

  @ViewChild('openAddFormula')
  openAddFormula!: ElementRef;

  @ViewChild('openUpdateFormula')
  openUpdateFormula!: ElementRef;

  @ViewChild('closeAddButton')
  closeAddButton!: ElementRef;

  
  @ViewChild('closeUpdateButton')
  closeUpdateButton!: ElementRef;

  constructor(
    private httpService: HttpClientService<any>,
    private router: Router
  ) { }
  get environement(): typeof environment {
    return environment;
  }
  get formuleType(): typeof FormuleTypeEnum {
    return FormuleTypeEnum;
  }

  async ngOnInit(): Promise<void> {
    this.getSites();
    this.initializeHeader();
    this.initializeForm();
    this.initializeUpdateForm();
    await this.loadAllSubsriptions();
    await this.loadSubsriptions()
    this.formuleTypes = Object.entries(FormuleTypeEnum)
      .filter(([key,]) => isNaN(Number(key as any)))
      .map((entry) => ({ index: entry[1] as number, name: entry[0] }));
  }

  openAddModal() {
    this.openAddFormula.nativeElement.click();
  }

  OpenUpdateFormulaPopup(id:any) {
    this.initializeUpdateForm();
    this.openUpdateFormula.nativeElement.click();
    this.getformulaById(id);
  }
  getformulaById(id:any){
    this.httpService.findAll('SubscriptionTypes/id', {
      Id:id
    })
    .subscribe((result: any) => {
      this.formulaToUpdate = result;
      this.populateForm(this.formulaToUpdate);
    });
  }
  private populateForm(formula:any): void {
    this.updateFormulaForm.controls['title'].setValue(
      formula.title
    );
    this.updateFormulaForm.controls['formulaType'].setValue(
      formula.formulaType
    );
    this.updateFormulaForm.controls['formulaCode'].setValue(
      formula.formulaCode
    );
    this.updateFormulaForm.controls['order'].setValue(
      formula.order
    );
    this.updateFormulaForm.controls['siteId'].setValue(
      formula.siteId
    );
  }
  private initializeForm(): void {
    this.formulaForm = new FormGroup({
      title: new FormControl('',Validators.required),
      formulaType: new FormControl('',Validators.required),
      formulaCode: new FormControl('',Validators.required),
      order: new FormControl('',Validators.required),
      siteId: new FormControl('',Validators.required),
    });
  }
  private initializeUpdateForm(): void {
    this.updateFormulaForm = new FormGroup({
      title: new FormControl('',Validators.required),
      formulaType: new FormControl('',Validators.required),
      formulaCode: new FormControl('',Validators.required),
      order: new FormControl('',Validators.required),
      siteId: new FormControl('',Validators.required),
    });
  }
  UpdateFormula(){
    this.httpService
    .patchFormData('SubscriptionTypes', this.formulaToUpdate.id, {
      Id: this.formulaToUpdate.id,
      Title: this.updateFormulaForm?.value.title,
      FormulaType: this.updateFormulaForm?.value.formulaType,
      FormulaCode:this.updateFormulaForm?.value.formulaCode,
      Order: parseInt(this.updateFormulaForm?.value.order || "0"),
      SiteId: this.updateFormulaForm?.value.siteId,
    })
    .subscribe((data) => {
      this.closeUpdateButton.nativeElement.click();
      this.loadAllSubsriptions();
      this.loadSubsriptions();
      this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => {
            this.router.navigate(['/super-admin/formulas']);
          });
    });

  }
  async loadSubsriptions(fromFilter?: boolean): Promise<void> {
    if (this.SubscriptionFilter) {
      this.httpService
        .findAll('SubscriptionTypes/SubscriptionTypesWithPagination', {
          pageSize: this.pageSize,
          pageNumber: 1,
          Title: this.SubscriptionFilter,
        })
        .subscribe((st) => {
          this.SubscriptionTypesList = st.items;
          this.totalRows = st.totalCount;
        });
    }
    else {
      this.httpService
        .findAll('SubscriptionTypes/SubscriptionTypesWithPagination', {
          pageSize: this.pageSize,
          pageNumber: this.currentPage,
        })
        .subscribe((st) => {
          this.SubscriptionTypesList = st.items;
          this.totalRows = st.totalCount;
        });
    }

    this.displayedColumns = [
      'title',
      'formulaType',
      'formulaCode',
      'order',
      'siteId',
      'Id'

    ];
  }

  async loadAllSubsriptions(fromFilter?: boolean): Promise<void> {
    this.httpService
      .findAll('SubscriptionTypes/SubscriptionTypesWithPagination', {
        pageSize: 200,
        pageNumber: 1,
      })
      .subscribe((st) => {
        this.SubscriptionTypesListAll = st.items;
      });
  }


  async getSites(): Promise<void> {
    this.httpService.findAll('Sites', {
      pageSize: 100,
      SortName: "name",
      SortDirection: "Asc"
    }).subscribe((sites) => {
      this.listSites = sites.items
    });
  }

  resetSearchPermission() {
    this.SubscriptionFilter = null;
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.loadSubsriptions();
  }

  initializeHeader(): void {
    this.displayedColumns = [
      'title',
      'formulaType',
      'formulaCode',
      'order',
      'siteId',
      'Id'
    ];
  }
  addFormula() {
    if(this.formulaForm.valid){
      let payload: any = {
        Title: this.formulaForm?.value.title,
        FormulaType: this.formulaForm?.value.formulaType,
        FormulaCode:this.formulaForm?.value.formulaCode,
        Order: parseInt(this.formulaForm?.value.order || "0"),
        SiteId: this.formulaForm?.value.siteId,
      };
      this.httpService.postFormData('SubscriptionTypes', payload).subscribe({
        next: (data) => {
        this.closeAddButton.nativeElement.click();
        this.initializeForm()
        this.loadAllSubsriptions();
        this.loadSubsriptions();
          this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => {
            this.router.navigate(['/super-admin/formulas']);
          });
        },
        error(err) { },
      });
    }

  }
  cancelAddFormula(){
    this.initializeForm()
  }

}
